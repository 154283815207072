import { setLoading, withCallState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject, Signal } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, type, withComputed, withMethods, withState } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, pipe, switchMap, tap } from 'rxjs';

import { SitesApiService } from 'app/core/services/sites-api/sites-api.service';
import { AuthStore, SitesStore } from 'app/core/stores';

import { Compartment, Greenhouse, Row, SiteTopology, Topology } from '../../models';
import { Level } from '../../models/level.model';
import {
    extractItemsFromParent,
    findItemsById,
    handleError,
    handleUpdateSuccess,
    isSameTopologySelection,
    TopologySelectionParams,
} from '../../utils/store-helper-utils';

const TopologyCollection = 'topology';

export type TopologyStoreType = InstanceType<typeof TopologyStore>;

export type SelectedZoneState = {
    selectedSiteId: string;
    selectedGreenhouseId: string;
    selectedCompartmentId: string;
    selectedRowId?: string;
};

const initialSelectedZone = {
    selectedSiteId: '',
    selectedGreenhouseId: '',
    selectedCompartmentId: '',
} as SelectedZoneState;

export const TopologyStore = signalStore(
    { providedIn: 'root' },
    withState(initialSelectedZone),
    withEntities({ collection: TopologyCollection, entity: type<SiteTopology>() }),
    withCallState({ collection: TopologyCollection }),
    withDevtools('TopologyStore'),
    withComputed((store) => {
        const selectedSite: Signal<SiteTopology | undefined> = computed(() => {
            return findItemsById(store.topologyEntities() ?? [], store.selectedSiteId());
        });

        const greenhouses: Signal<Greenhouse[]> = computed(() => {
            return selectedSite()?.complexes ?? [];
        });

        const selectedGreenhouse: Signal<Greenhouse | undefined> = computed(() => {
            return findItemsById(selectedSite()?.complexes ?? [], store.selectedGreenhouseId());
        });

        const compartments: Signal<Compartment[]> = computed(() => {
            const greenhouse = selectedGreenhouse();
            if (!greenhouse) {
                return [];
            }

            return extractItemsFromParent<Greenhouse, Level, Compartment>(greenhouse, 'levels', 'spaces');
        });

        const selectedCompartment: Signal<Compartment | undefined> = computed(() => {
            const selectedCompartments = compartments();
            return findItemsById(selectedCompartments, store.selectedCompartmentId());
        });

        const rows: Signal<Row[]> = computed(() => {
            const compartment = selectedCompartment();
            if (!compartment) {
                return [];
            }
            return extractItemsFromParent<Compartment, never, Row>(compartment, 'sections');
        });

        const selectedZone: Signal<SelectedZoneState> = computed(() => {
            return {
                selectedSiteId: store.selectedSiteId(),
                selectedGreenhouseId: store.selectedGreenhouseId(),
                selectedCompartmentId: store.selectedCompartmentId(),
            } as SelectedZoneState;
        });

        return {
            selectedSite,
            greenhouses,
            selectedGreenhouse,
            compartments,
            selectedCompartment,
            rows,
            selectedZone,
        };
    }),
    withMethods((store) => {
        const sitesApiService = inject(SitesApiService);
        const translate = inject(TranslateService);
        const authStore = inject(AuthStore);
        const sitesStore = inject(SitesStore);

        const loadTopology = rxMethod<{ siteId?: string; forceLoad?: boolean }>(
            pipe(
                distinctUntilChanged(
                    (previous, current) => previous.siteId === current.siteId && !current.forceLoad,
                ),
                filter(
                    ({ siteId, forceLoad }) =>
                        !!siteId && (forceLoad || !store.topologyIds().includes(siteId)),
                ),
                tap(() => patchState(store, { ...setLoading(TopologyCollection) })),
                switchMap(({ siteId }) =>
                    sitesApiService.getTopology(authStore.tenantId()!, siteId!).pipe(
                        map(
                            (topology: Topology) =>
                                ({
                                    id: siteId,
                                    complexes: topology.complexes.map((complex: Greenhouse) => ({
                                        ...complex,
                                        groundFloorLevelId: complex.levels?.[0]?.id ?? 'none',
                                    })),
                                }) as SiteTopology,
                        ),
                        tapResponse({
                            next: (complexes: SiteTopology) => {
                                handleUpdateSuccess(
                                    store,
                                    complexes,
                                    (siteTopology) => siteTopology.id,
                                    TopologyCollection,
                                );
                            },
                            error: () => {
                                handleError(
                                    store,
                                    translate.instant('ERROR.LOADING_TOPOLOGY', { site: siteId }), //ToDo: Change to sitename when topolgy has info about sites
                                    TopologyCollection,
                                );
                            },
                        }),
                    ),
                ),
            ),
        );

        const reloadTopology = () =>
            loadTopology({
                siteId: store.selectedSiteId(),
                forceLoad: true,
            });

        const selectTopology = rxMethod<TopologySelectionParams>(
            pipe(
                distinctUntilChanged((prev, current) => isSameTopologySelection(prev, current)),
                tap(({ siteId, greenhouseId, compartmentId }) => {
                    patchState(store, {
                        selectedSiteId: siteId,
                        selectedGreenhouseId: greenhouseId,
                        selectedCompartmentId: compartmentId,
                    });
                }),
                tap(({ siteId }) => loadTopology({ siteId })),
            ),
        );

        const getAllNamesOfSite: () => string[] = () => {
            const site = sitesStore.sitesEntities().find((site) => site.id === store.selectedSiteId());
            const siteName = site?.name;
            if (!siteName) {
                return [];
            }
            return [siteName, ...extractAllNamesFromGreenhousesAndInnerObjects(store.greenhouses())];
        };

        const getAllRowNumbers: () => number[] = () => {
            return store
                .greenhouses()
                .flatMap((greenHouse) =>
                    greenHouse.levels?.flatMap((level) =>
                        level.spaces?.flatMap((compartment) =>
                            compartment.sections?.map((section) => section.number),
                        ),
                    ),
                )
                .filter((value) => value != undefined);
        };

        return { loadTopology, reloadTopology, selectTopology, getAllNamesOfSite, getAllRowNumbers };
    }),
);

function extractAllNamesFromGreenhousesAndInnerObjects(greenhouses: Greenhouse[]) {
    const greenhouseNames: string[] = [];
    const compartmentNames: string[] = [];
    const rowNames: string[] = [];
    greenhouses.forEach((greenhouse) => {
        greenhouseNames.push(greenhouse.name);
        if (!greenhouse.levels?.length) {
            return;
        }
        greenhouse.levels.forEach((level) => {
            if (!level.spaces?.length) {
                return;
            }
            level.spaces.forEach((compartment) => {
                compartmentNames.push(compartment.name);
                if (!compartment.sections?.length) {
                    return;
                }
                compartment.sections.forEach((row) => rowNames.push(row.name));
            });
        });
    });
    return [...greenhouseNames, ...compartmentNames, ...rowNames];
}
